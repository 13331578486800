.container {
    width: 100%;
    height: 100%;
    /* background: #6C7A89; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    }
    .box {
    width: 60%;
    height: 100%;
    background: #fff;
    }