.footer-buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  margin-bottom: 50px;
}

.footer-buttons-carrinho {

  bottom: 0;
  width: 100%;
  display: flex;
}

.carrinho-button,
.finalizar-button {
  width: 100%;
  height: 50px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;


}

.carrinho-button {
  background-color: rgb(19, 0, 187);
}

.finalizar-button {
  background-color: green;
}






.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.modal h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.modal p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.modal button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}