.mesa {
    overflow: auto;
    padding: 10px;
    height: 100%;
    width: 100%;
    
  }

.mes-box{
    height: 20vh;
    width: 20vh;
    border: 3px solid #ccc;
    border-radius: 5px;    
    padding: 10px;
    margin-left: 10px;
    margin-top: 10px;
    display: inline-block;
}  
  
  .numero-mesa {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }
  
  .status-mesa {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    padding: 5px;
    border-radius: 5px;
  }

  
  .aguardando {
    background-color: #ffff00;
    color: #000;
  }
  
  .consumindo {
    background-color: #00ff00;
    color: #fff;
  }
  
  .consumo-mesa {
    font-size: 25px;
    font-weight: bold;
    text-align: right;
    margin-top: 10px;
  }
  