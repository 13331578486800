.footer {
    text-align: center;
    background-color: #eee;
    padding: 5px;
}

.footer-text {
    margin: 0;
    font-size: 12px;
}

.footer-text-principal {
    margin: 0;
    font-size: 14px;
}