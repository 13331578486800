.lista-categorias {
  overflow-x: auto;
  scrollbar-width: none; /* Oculta a barra de rolagem no Firefox */
  -ms-overflow-style: none; /* Oculta a barra de rolagem no IE e Edge */
  top: 0;
  position: sticky;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra sutil */
  /* z-index: 9; Garante que a barra fique acima do conteúdo */
  padding: 10px 0; /* Adiciona um pouco de espaçamento superior e inferior */
}

.lista-categorias::-webkit-scrollbar {
  display: none; /* Oculta a barra de rolagem no Chrome, Safari e Opera */
}

.lista-categorias ul {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 10px; /* Adiciona um pouco de espaçamento nas laterais */
  white-space: nowrap;
}

.lista-categorias li {
  margin-right: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animações suaves */
  border-radius: 8px; /* Borda mais arredondada */
}

.lista-categorias li:hover {
  transform: translateY(-5px); /* Levanta o item ao passar o mouse */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra ao item */
}

.lista-categorias li:last-child {
  margin-right: 0;
}

.lista-categorias a {
  display: block;
  padding: 10px 15px; /* Mais espaço em torno do texto */
  color: #333;
  text-decoration: none;
  background-color: #f5f5f5; /* Fundo claro por padrão */
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transição suave */
}

.lista-categorias li.active a {
  color: #fff;
  background-color: #290505; /* Cor de fundo para o item ativo */
}

.lista-categorias a:hover {
  background-color: #ddd; /* Muda a cor ao passar o mouse */
}

.header-adicional {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.adicional-titulo {
  text-align: right;
  margin-right: 10px;
}

.adicional-qtd {
  background-color: black;
  color: white;
  padding: 5px;
  margin-left: 10px;
}

.footer-buttons-categoria {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
}
