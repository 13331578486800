.input-with-buttons-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.text-container {
  display: flex;
  flex-direction: column;
  /* Altera a direção dos elementos para coluna */
}

.input-container {
  display: flex;
  align-items: center;
  /* Alinha os elementos verticalmente */
  margin-left: auto;
  /* Alinha o container à direita */
}

.input-box {
  width: 50px;
  background-color: white;
  border: none;
  text-align: center;
}

.buttons-container {
  display: flex;
}

.decrement-button {
  width: 40px;
  height: 40px;
  background-color: rgb(134, 0, 0);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  text-align: center;
  border: "1px solid black";
}

.increment-button {
  width: 40px;
  height: 40px;
  background-color: rgb(0, 85, 0);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 50%;
  text-align: center;
  border: "1px solid black";
}

.adicional-button {
  width: auto;
  background-color: #00612c;
}

.quantidade-button {
  width: 55px;
}

.adicional-button,
.quantidade-button {
  border: 0;
  height: 50px;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.input-observacao {
  margin: 10px;
}
