.descricao-carrinho {
    background-color: black;
    color: white;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.descricao-carrinho h1 {
    margin: 0;
    font-size: 24px;
}

.product-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.product-item {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;

    margin-bottom: 10px;
    padding: 10px;

}

.product-image {
    float: left;
    margin-right: 10px;
    height: 100px;
    width: 100px;
}

.product-info {
    flex: 1;
}

.product-title {
    margin: 0;
    font-size: 24px;
}

.product-description {
    margin: 0;
    font-size: 16px;
}

.product-price-unitario {
    margin: 0;
    font-size: 12px;
    text-align: right;
    margin-right: 10px;
    color: rgb(116, 116, 116);
}

.product-price-total {
    margin: 0;
    font-size: 15px;
    text-align: right;
    margin-right: 10px;
    color: rgb(116, 116, 116);
}

.delete-icon {
    margin-left: auto;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    color: red;
    cursor: pointer;
}



.input-number {
    /* width: 60%; */
    /* padding: 0.5rem; */
    text-align: right;
    display: inline-block;
}

.button-coupon {
    /* width: 40%; */
    margin-left: 5px;
    display: inline-block;
}

.foter {
    position: fixed;
    bottom: 0;
    width: 100%;
    /* display: flex; */

    margin-bottom: 50px;
    background-color: #fff;
    background-color: rgb(3, 102, 0);
}