* {
  box-sizing: border-box;
}

.merchant-info {
  border-radius: 4px 4px 0 0;
  padding: 20px 20px 0;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  top: -5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.merchant-info__content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.merchant-info__logo {
  border-radius: 100%;
  height: 100px;
  width: 100px;
  margin-right: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-self: flex-start;
}

.merchant-info__content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.merchant-info__title-container {
  flex: 1 1;
  display: inline;
}

.merchant-info__title {
  display: inline;
  font-size: 1.9rem;
  font-weight: 500;
  line-height: 24px;
  color: #3e3e3e;
  margin: 0 0 2px;
}

.merchant-info__detail-container {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
}

.merchant-details__button {
  display: flex;
  align-items: center;
  outline: none;
  border: 0;
  background-color: transparent;
  color: #ea1d2c;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  cursor: pointer;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.merchant-info__minimum-order {
  color: #a6a6a5;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 4px;
}

.restaurant-menu__menu-search {
  display: flex;
  position: relative;
  flex: 3 1;
  align-items: center;
  transition: all 0.2s ease-in-out, border 0ms;
  padding: 20px 16px 0;
  border-bottom: 0;
  margin-top: 0;
}

.restaurant-menu-search {
  position: relative;
  width: 100%;
  height: 44px;
}

.restaurant-menu__menu-search .icon-marmita--search {
  width: 20px;
  height: 20px;
}

.restaurant-menu__menu-search .restaurant-menu-search__field {
  background-color: #f2f2f2;
  border-radius: 4px;
  height: 44px;
  width: 100%;
}

.restaurant-menu-search .icon-marmita {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  pointer-events: none;
}

.restaurant-menu-search__field {
  display: flex;
  height: 40px;
  width: 99%;
  padding-left: 55px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

.icon-marmita {
  display: inline-block;
  position: relative;
}

.restaurant-menu__menu-search .restaurant-menu-search__field {
  background-color: #f2f2f2;
  border-radius: 4px;
  height: 44px;
  width: 100%;
}

.icon-marmita icon-marmita--search {
  height: 20px;
  width: 20px;
}

.restaurant-menu-search__field {
  display: flex;
  height: 40px;
  width: 99%;
  padding-left: 55px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
}

button,
input {
  overflow: visible;
}

.restaurant-menu__header restaurant-menu__header--sticky {
  top: 60px;
  position: fixed;
  left: 0px;
  width: 375px;
}

.restaurant-menu-group__container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, minmax(320px, 1fr));
  grid-gap: 0;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

ul > li {
  list-style: none;
}

.dish-card-wrapper {
  position: relative;
  margin-bottom: 14px;
}

.dish-card__description {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.dish-card__details {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.dish-card .dish-card__container-image {
  position: relative;
}

.dish-card {
  position: relative;
  display: grid;
  min-height: 147px;
  width: 100%;
  background: #fff;
  padding: 20px;
  text-decoration: none;
  transition: 200ms;
  overflow: hidden;
  height: 100%;
}

.dish-card__container-image {
  position: relative;
}

/* CHATGPT */
.lista-produtos {
  overflow-y: auto;
  width: 95%;
  margin-left: 10px;
  margin-right: 10px;
}

/* altura da lista com scroll */

.produto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  /* height: 300px; */
}

.produto img {
  width: 100%;
  height: 100%;
  margin-right: 10px;
}

.produto-img {
  width: 110px;
  height: 110px;
  margin-right: 10px;
}

.produto-info {
  width: 100%;
  /* height: 100px; */
}

.produto h4 {
  font-size: 15px;
  font-weight: bold;
  /* margin-top: 5px; */
}

.produto p {
  /* margin-top: -10px; */
  font-size: 10px;
  color: #565e56;
}

.produto h5 {
  font-size: 12px;
  color: #009e00;
  /* margin-top: -10px; */
}

.produto h6 {
  font-size: 14px;
  color: #888;
  height: 10px;
  /* margin-bottom: -10px; */
}

.text-block-387 {
  display: inline-block;
  padding: 3px;
  /* float: left; */
  border-radius: 4px;
  background-color: #1fb76c;
  color: #fff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
}
