.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;




}

.modal-auto {
  background-color: white;
  /* padding: 20px; */
  border-radius: 5px;
  animation: fade-in 0.3s;
  height: 100%;
  width: 100%;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;

  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  /* Adicione esta propriedade para garantir que o botão fique acima do conteúdo do modal */
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  /* Adicione esta propriedade para garantir que o botão fique acima do conteúdo do modal */
}

.modal-content {
  z-index: 0;
  /* Adicione esta propriedade para garantir que o conteúdo fique abaixo do botão "Fechar" */
}

.lista {
  position: absolute;
  padding: 10px;

  /* altura da outra div com scroll */
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}