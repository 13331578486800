header {
  display: block;
}

.merchant-banner {
  position: relative;
  margin: auto;
}

.merchant-banner__wrapper {
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #000000;
  background-color: #000000;
  height: 118px;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50%;
  justify-content: center;
  /* background-image: url(https://static-images.ifood.com.br/image/upload//capa/94453601-798f-40c5-958b-4e390bcf6a95/202203230959_wQlU_i@2x.jpg); */
  position: relative;
  /* adiciona posição relativa para que o ::before possa ser posicionado corretamente */
}

.merchant-banner__wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /* cor semi-transparente */
  z-index: 1;
  /* garante que o pseudo-elemento fique acima da imagem */
}