.product-list-resumo {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.product-list-resumo li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px dashed #575757;
    padding-bottom: 10px;
}

.product-list-resumo li img {
    margin-right: 10px;
    border-radius: 50%;
}

.product-list-resumo li h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    font-family: "Courier New", Courier, monospace;
}

.product-list-resumo li p {
    margin: -3px 10px 0px 0px;
    font-size: 14px;

}

.total {
    font-family: "Courier New", Courier, monospace;
}