.home-page {
    height: 30vh;
    width: 100%;
    background-color: #b1070799;


}

.img-home-page {
    width: '25%';
    margin-top: '-13%';
}

.container-dev {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 2em;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.cCCufa {
    /* display: flex; */
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: white;
    width: 100%;
    margin-top: 10px;
    padding: 2rem;
    font-size: 1.2rem;
    font-family: Author-Regular;
}

/* .jgLpfR {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(10, 8, 6);
    color: white;
    display: flex;
    flex-direction: column;
    height: 90%;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
}

.lgLGPI {
    text-align: center;
    z-index: 1;
} */

.card {
    background: red;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    

}

.card-center{
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 2.5rem;
    flex-direction: column;

}

.card-image{
    height: 150px;
    width: 150px;
}

a{
    text-decoration: none;
    color: black;
}