@import url('https://fonts.cdnfonts.com/css/circular-std');

img {
  border-style: none;
}


* {
  font-family: 'segoe ui', sans-serif;
  margin: 0px;
}

.carregamento {
  height: 100%;
  width: 100%;
  margin: auto;
  /* Define as margens superior e inferior como "auto" */
  position: absolute;
  /* Define a posição como "absoluta" */
  top: 0;
  /* Define o topo como "0" */
  bottom: 0;
  /* Define a parte inferior como "0" */
  left: 0;
  /* Define a esquerda como "0" */
  right: 0;
  /* Define a direita como "0" */
  background-color: rgba(0, 0, 0, 0.6);
  /* Fundo preto com 0.6 de transparência */
}


#loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* html,
body,
div {
  overflow: hidden;
} */

.blur-effect {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}