.container {
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    align-items: center;
    margin: 10px;
}

.icon {
    margin-right: 10px;
}

.icon img {
    display: block;
}

.content h2 {
    margin: 0 0 5px;
    font-size: 1.2em;
}

.content p {
    margin: 0;
    font-size: 1em;
}






.container-social {
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    padding: 10px;
}

.icon-social {
    margin-right: 10px;
}

.icon-social img {
    display: block;
}

.content-social p {
    margin: 0;
}

.margin {
    margin-left: 100px;

}