  
  .header h1 {
    margin: 0;
  }
  
  .header h2 {
    /* margin-top: 10px; */
    font-size: 1.2em;

  }


  .header {
    background-color: black;
    color: white;
    /* text-align: center; */
    padding: 10px;
    height: 115px;
  }
  
  .img-left {
    float: left;
    margin-right: 10px;
    width: 100px;
    height: 100px;
    
  }